import React from 'react'
import cx from 'classnames'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { Modal } from 'components/feedback'
import { Carousel, Image, Video } from 'components/dataDisplay'

import s from './FullScreenModal.scss'


type FullScreenModalProps = {
  images: string[]
  videos?: string[]
  initialSlide?: number
  isWithLegend?: boolean
  isImageTrueSize?: boolean
}

const FullScreenModal: React.FC<FullScreenModalProps & ModalVisibilityProps> = (props) => {
  const { initialSlide = 0, images, videos, isWithLegend = true, isImageTrueSize = false, closeModal } = props

  return (
    <Modal
      className={cx(s.modal, 'justify-start')}
      title=""
      closeModal={closeModal}
    >
      <div className="flex justify-center">
        <Carousel
          className="radius-16 overflow-hidden"
          slidesToShow={1}
          withArrows
          withLegend={isWithLegend}
          initialSlide={initialSlide}
        >
          {
            images.map((image, index) => (
              <Image
                className={isImageTrueSize ? 'w-full h-full' : null}
                key={index}
                src={image}
                tag={isImageTrueSize ? null : 'div'}
                width={isImageTrueSize ? null : '774'}
                height={isImageTrueSize ? null : '774'}
                withLoader={false}
                noResize={true}
              />
            ))
          }
          {
            videos.map((video) => (
              <div
                key={video}
                className="relative"
                style={{ width: '774px' }}
              >
                <Video
                  className="w-full h-full"
                  type="mp4"
                  url={video}
                  controls
                />
              </div>
            ))
          }
        </Carousel>
      </div>
    </Modal>
  )
}

export const openFullScreenModal = (props: FullScreenModalProps) => openModal('fullScreenModal', props)


export default modalVisibility('fullScreenModal', FullScreenModal)